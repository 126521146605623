// npm imports
import React, { useState, useEffect } from "react";
import { updateUserAttributes, fetchUserAttributes } from 'aws-amplify/auth';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PageTitle from '../components/PageTitle';
import Alert from "react-bootstrap/Alert";


export default function Settings() {

    const [givenName, setGivenName] = useState("");
    const [familyName, setFamilyName] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [disableSaveButton, setDisableSaveButton] = useState(false);

    // used for the alert at the bottom
    const [successfullUpdate, setSuccessfullUpdate] = useState(false);
    const [message, setMessage] = useState("");


    useEffect(() => {
        /*
            get the given and family name of the current user
        */
        async function onLoad() {
            console.log("In the onLoad function!");
            const attributes = await fetchUserAttributes();
            setGivenName(attributes.given_name);
            setFamilyName(attributes.family_name);

        }

        onLoad();
        setDisabled(true);
    }, []); // add empty array so this only runs once (rather than every time the state changes)


    function unlock() {
        setDisabled(false);
        console.log(disabled);
        setSuccessfullUpdate(false);
        setMessage("");
    };


    function updateName() {
        updateUserAttributes(
            {
                userAttributes: {
                    family_name: familyName,
                    given_name: givenName
                }
            }, function (err, result) {
                if (err) {
                    setMessage(JSON.stringify(err));
                    console.log(JSON.stringify(err));
                } else {
                    // put up success message for the user
                    setSuccessfullUpdate(true);
                    setMessage("Update successfull!");
                }
            })
        setDisabled(true);
    }

    function updateGivenName(val) {
        setGivenName(val);
        if (val.length === 0 || familyName.length === 0) {
            setDisableSaveButton(true);
        } else {
            setDisableSaveButton(false);
        }
    }

    function updateFamilyName(val) {
        setFamilyName(val);
        if (givenName.length === 0 || val.length === 0) {
            setDisableSaveButton(true);
        } else {
            setDisableSaveButton(false);
        }
    }

    return (
        <>
            <PageTitle title="Settings" />
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="center">
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                        disabled={disabled}
                        label="First Name"
                        variant="outlined"
                        value={givenName}
                        onChange={(e) => updateGivenName(e.target.value)}
                    />

                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}>
                    <TextField
                        disabled={disabled}
                        label="Last Name"
                        variant="outlined"
                        value={familyName}
                        onChange={(e) => updateFamilyName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    {disabled === true ? (
                        <Button
                            variant="contained"
                            onClick={unlock}>
                            Modify
                        </Button>
                    ) : (
                        <Button
                            disabled={disableSaveButton}
                            color="success"
                            variant="contained"
                            onClick={updateName}>
                            Save
                        </Button>
                    )}
                </Grid>
                <Grid item>
                    {successfullUpdate && (
                        <Alert className="m-3 text-center" variant="success">
                            {message}
                        </Alert>
                    )}
                </Grid>
            </Grid>
        </>

    );
}