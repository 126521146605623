/* 
    This is the Moment page
*/

// npm imports
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { DateTime } from "luxon";

// amplify imports
import { getCurrentUser } from "aws-amplify/auth";

// MUI imports
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Grid from '@mui/material/Grid';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';


// local imports
// @ts-ignore todo fix later
import MomentSettings from "./MomentSettings";
// @ts-ignore todo fix later
import MomentAddPeople from "../components/MomentAddPeople";
// @ts-ignore todo fix later
import ContributorChips from "../components/ContributorChips";
import MediaCount from "../components/MediaCount";
import MediaModal from "../components/MediaModal";
import OwnerChip from "../components/OwnerChip";
import UploadComponent from "../components/UploadComponent";
import JoinButton from "../components/JoinButton";
import PageTitle from "../components/PageTitle";
import CustomMasonry from "../components/CustomMasonry";
import { MomentData } from "../interfaces/interfaces";
import { acceptedFileTypes, acceptedPhotoFileTypes, acceptedVideoFileTypes } from "../libs/vars";


//const onError = require('../libs/errorLib');
//const MomentSettings = require('./MomentSettings');
//const MomentAddPeople = require('../components/MomentAddPeople');
//const MomentClickPicture = require('../components/MomentClickPicture');


export interface MomentProps {
    momentInfo: MomentData
    updateMomentCallback(): any
}

export default function Moment({ momentInfo, updateMomentCallback }: MomentProps) {


    // @ts-ignore todo fix later
    const { id } = useParams();

    const [filesToUpload, setFilesToUpload] = useState<FileList>()

    const [showSettings, setShowSettings] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [isContributor, setIsContributor] = useState(false);

    const [showAddPeople, setShowAddPeople] = useState(false);

    // used to track which file (if any) has been clicked and is shown in the media modal
    const [focusedFileIndex, setFocusedFileIndex] = useState<number | null>(null);

    const [currentUsername, setCurrentUsername] = useState<string | undefined>(undefined);

    const [openShareSnackbar, setOpenShareSnackbar] = useState(false);

    /*
        These are created so we can substitue the default upload file button (which looks ugly) for a custom button
    */
    const hiddenFileInput = useRef(null);
    const handleClick = (event: any) => {
        // @ts-ignore todo fix later
        hiddenFileInput.current.click();
        console.log(event);
    };

    /* 
        When we load a Moment we want to get the current user and
        store that in state. If either the moment or the current user
        changes, then run the below useEffect, to re-check if they are
        the owner or a contributor to the board.
    */

    async function updateCurrentUsername() {
        var tmpUsername: string | undefined = undefined;
        try {
            const { username } = await getCurrentUser();
            console.log(`Successful call of getCurrentUser(), username is ${username}`);
            tmpUsername = username;
            setCurrentUsername(username);
        } catch (UserUnAuthenticatedException) {
            console.log(`Unsuccessful call of getCurrentUser(), no longed in user`);
            setCurrentUsername(undefined);
        }

        if (tmpUsername === undefined) {
            console.log("Username is undefined, not logged in");
            setIsOwner(false);
            setIsContributor(false);
        } else if (tmpUsername === momentInfo.info.owner.username) {
            console.log("The current viewer is the owner");
            setIsOwner(true);
        } else {
            console.log("The current viewer is not the owner");
            setIsOwner(false);
        }

        // check if the current user is a contributor
        if (momentInfo.info.contributors.find(e => e.username === tmpUsername)) {
            console.log("The current user is a contributor");
            setIsContributor(true);
        } else {
            console.log("The current user is neither, not a contributor")
            setIsContributor(false);
        }
    }


    useEffect(() => {
        // this is effectively calling an 'external system'
        updateCurrentUsername();
    }, [momentInfo]);


    async function handleFileChange(event: any) {
        const files: FileList = event.target.files;
        console.log(files);
        setFilesToUpload(files);
    }


    function settingsClick() {
        setShowSettings(true);
    }

    function addPeopleClick() {
        setShowAddPeople(true);

    }

    function imageClicked(index: number) {
        console.log(`Index is ${index}`);
        setFocusedFileIndex(index);
    }

    function addDotAndCombine(strings: string[]): string {
        const stringsWithDots = strings.map(str => `.${str}`);
        const combinedString = stringsWithDots.join(', ');
        return combinedString;
    }


    async function shareButtonClicked() {
        // Copy current URL to clipboard
        navigator.clipboard.writeText(window.location.href)
            .then(() => {
                setOpenShareSnackbar(true);
            })
            .catch((error) => {
                console.error('Failed to copy URL to clipboard: ', error);
                alert('Failed to copy URL to clipboard. Please try again.');
            });
    }


    function closeShareSnackbar(event: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        setOpenShareSnackbar(false);
    };

    function renderButtons() {

        return (
            <>
                <Form.Group controlId="file">
                    <Form.Control onChange={handleFileChange} style={{ display: 'none' }} ref={hiddenFileInput} type="file" multiple accept={addDotAndCombine(acceptedFileTypes)} />
                </Form.Group>
                <Snackbar
                    open={openShareSnackbar}
                    autoHideDuration={2000}
                    onClose={closeShareSnackbar}
                    message="Link copied to clipboard"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mb: 1 }}>

                    {/* Render the Upload Files button */}
                    {(isOwner || isContributor) && (

                        <Grid item xs={6} sm={6} md={3} lg={2}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                //alignItems: 'center',
                                //height: '100%'
                            }}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleClick}>
                                Upload Files
                            </Button>
                        </Grid>
                    )}

                    {/* 
                    if you're the owner of the moment
                    OR you're a contributor and the moment is secret
                    then allowed to edit attendees
                */}
                    {((isOwner || (isContributor && momentInfo.info.secret == true))) && (
                        <Grid item xs={6} sm={6} md={3} lg={2}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                //alignItems: 'center',
                                //height: '100%'
                            }}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={addPeopleClick}>
                                Edit Attendees
                            </Button>
                        </Grid>
                    )}

                    {/* Show the copy link button */}
                    <Grid item xs={6} sm={6} md={3} lg={2}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            //alignItems: 'center',
                            //height: '100%'
                        }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={shareButtonClicked}>
                            Copy Link
                        </Button>
                    </Grid>

                    {isOwner && (
                        <Grid item xs={6} sm={6} md={3} lg={2}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                //alignItems: 'center',
                                //height: '100%'
                            }}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={settingsClick}>
                                Settings
                            </Button>
                            <MomentSettings
                                showSettings={showSettings}
                                setShowSettings={setShowSettings}
                                id={id}
                                name={momentInfo.info.name}
                                secret={momentInfo.info.secret}
                                joinable={momentInfo.info.joinable}
                                date={momentInfo.info.date}
                                updateMomentCallback={updateMomentCallback}
                            />
                        </Grid>
                    )}
                </Grid >
            </>
        )
    }


    function renderTitle() {
        return (
            <Typography
                variant="h4"
                mb={3}
                align="center"
                sx={{ pt: 1, mb: 2 }}>
                {momentInfo.info.name}
            </Typography>
        )
    }


    function renderDateTime() {
        if (momentInfo.info.date === null || momentInfo.info.date === undefined) {
            return (
                <></>
            )
        } else {
            return (
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center">
                    <Grid item sx={{ pr: 1 }}>
                        <CalendarMonthOutlinedIcon />
                    </Grid>
                    <Grid>
                        <Typography
                            className="text-center">
                            {DateTime.fromISO(momentInfo.info.date).toFormat("cccc d LLLL y h:mma")}
                        </Typography>
                    </Grid>
                </Grid >
            )
        }
    }

    function renderPadlock() {
        return (

            <Grid
                alignItems='center'
                justifyContent="center"
                container
                sx={{ pt: 2, pb: 2 }}>
                <Grid item sx={{ pl: 0.5, pr: 0.5 }}>
                    {momentInfo.info.secret == undefined &&
                        <></>
                    }
                    {momentInfo.info.secret == false &&
                        <Tooltip title="This Moment is public, anyone in the world can view it" enterTouchDelay={0} leaveTouchDelay={2000}>
                            <LockOpenOutlinedIcon fontSize="medium" color="success" />
                        </Tooltip>
                    }
                    {momentInfo.info.secret == true &&
                        <Tooltip title="This Moment is secret, only you and the people added to it can see it" enterTouchDelay={0} leaveTouchDelay={2000}>
                            <LockOutlinedIcon color="error" />
                        </Tooltip>
                    }
                </Grid>
                <Grid item sx={{ pr: 0.5 }}>
                    {momentInfo.info.secret == undefined &&
                        <></>
                    }
                    {momentInfo.info.secret == false &&
                        <Typography>Public</Typography>
                    }
                    {momentInfo.info.secret == true &&
                        <Typography>Secret</Typography>
                    }
                </Grid>
            </Grid>
        )
    }

    /* 
        This function takes in either 1 or -1 and is used to change
        the focused file when a user is scrolling through pictures in
        the modal.
        If 1, moves the focused file up by 1
        If -1, moves the focused file down by 1 
    */
    function updateFocusedFile(num: number) {
        console.log(`Current index is ${focusedFileIndex}`);
        const tmpNum = (focusedFileIndex ?? 0) + num
        if (tmpNum < 0) {
            console.log("Going to the end");
            setFocusedFileIndex(momentInfo.files.length - 1);
        } else if (tmpNum >= momentInfo.files.length) {
            console.log("Going to the beginning");
            setFocusedFileIndex(0);
        } else {
            console.log(`Updating it to ${tmpNum}`);
            setFocusedFileIndex(tmpNum);
        }
    }

    /*
        This function updates the Moment data after a file has been deleted.
        Also conducts some checks.
    */
    function deleteFileUpdateMomentCallback() {
        console.log(`Deleted a file, that index was ${focusedFileIndex}`);
        // first check if it was the very first file
        if (focusedFileIndex === 0 && momentInfo.files.length === 1) {
            console.log("You just deleted the very first picture");
            setFocusedFileIndex(null);
            // secondly check if it was the last file in the series
        } else if (focusedFileIndex ?? 0 + 1 === momentInfo.files.length) {
            console.log("That file was the last one we deleted!");
            const newIndex = (focusedFileIndex ?? 1) - 1;
            console.log(`Updating the index to ${newIndex}`)
            setFocusedFileIndex(newIndex);
        }
        // then call to update the Moment and re-fetch all the
        updateMomentCallback();
    }


    return (
        <>
            <PageTitle title={momentInfo.info.name} />
            {renderButtons()}

            {isOwner || isContributor ?
                <MomentAddPeople
                    visible={showAddPeople}
                    setVisible={setShowAddPeople}
                    id={id}
                    contributors={momentInfo.info.contributors.map(x => x.username)}
                    updateMomentCallback={updateMomentCallback}
                    momentName={momentInfo.info.name}
                    momentOwnerGivenName={momentInfo.info.owner.givenName}
                    momentOwnerFamilyName={momentInfo.info.owner.familyName}
                    momentURL={window.location.href}
                />
                :
                <></>
            }

            {focusedFileIndex !== null &&
                <MediaModal
                    setFocusedFileIndex={setFocusedFileIndex}
                    file={momentInfo.files[focusedFileIndex]}
                    updateMomentCallback={deleteFileUpdateMomentCallback}
                    updateFocusedFileCallback={updateFocusedFile}
                />
            }


            {
                filesToUpload !== undefined
                &&
                <UploadComponent files={filesToUpload} signedURLs={momentInfo.files} id={id} />
            }

            {renderTitle()}

            <OwnerChip
                name={`By ${momentInfo.info.owner.givenName} ${momentInfo.info.owner.familyName}`}
                numPhotos={momentInfo.files.filter(file => file.owner.username === momentInfo.info.owner.username && acceptedPhotoFileTypes.includes(file.fileType)).length}
                numVideos={momentInfo.files.filter(file => file.owner.username === momentInfo.info.owner.username && acceptedVideoFileTypes.includes(file.fileType)).length}
            />

            {renderDateTime()}

            {renderPadlock()}

            <MediaCount
                numPhotos={momentInfo.files.filter(file => acceptedPhotoFileTypes.includes(file.fileType)).length}
                numVideos={momentInfo.files.filter(file => acceptedVideoFileTypes.includes(file.fileType)).length}
            />

            <ContributorChips moment={momentInfo} />

            <JoinButton
                momentInfo={momentInfo}
                updateMomentCallback={updateMomentCallback}
            />

            <CustomMasonry
                files={momentInfo.files}
                imageClicked={imageClicked}
            />

        </>
    );
}
