/*
    This component acts as a security wrapper for the Moment page
    We use this wrapper because we want to allow unauthenticated people
    to access public moments but not to be able to access private moments
*/

// React imports
import React, { useState, useEffect } from "react";

// React Router imports
import { useParams, Redirect } from "react-router-dom";

// Amplify imports
import { get } from 'aws-amplify/api';
import { fetchAuthSession } from "aws-amplify/auth";

// MUI imports
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

// local imports
import Moment from "./Moment";
import { MomentData } from "../interfaces/interfaces";

// React Router imports
import { useHistory } from 'react-router-dom';


export default function MomentSecurityWrapper() {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loginRequired, setLoginRequired] = useState<boolean>(false);
    const history = useHistory();

    // @ts-ignore todo fix later
    const { id } = useParams();

    // Have to use empty data so that the HTML will load
    const [momentInfo, setMomentInfo] = useState<MomentData>(
        {
            info: {
                name: "Loading...",
                owner: {
                    username: "loading...",
                    givenName: "loading...",
                    familyName: "loading..."
                },
                secret: true,
                joinable: false,
                date: "",
                contributors: [],
            },
            files: []
        }
    );

    useEffect(() => {
        setIsLoading(true);
        loadMoment();
    }, [id]);

    /*
        Returns information about the moment + pre-signed URLS
    */
    async function loadMoment() {
        const apiInput = {
            apiName: 'boards',
            path: `/board/${id}`,
            options: {},
        };

        // if there is an id token present (i.e. the user is signed in) include it
        const { tokens } = await fetchAuthSession();
        if (tokens?.idToken) {
            apiInput.options = {
                headers: {
                    CognitoIdToken: tokens.idToken
                },
            }
        }

        console.log("About to get the moment API");

        get(apiInput).response.then((response) => {
            console.log("We have a result");
            console.log(response);
            // if the user needs to be authenticated first
            if (response.statusCode === 270) {
                console.log("Authentication required, diverting to authentication");
                setIsLoading(false);
                setLoginRequired(true);
            } else if (response.statusCode === 271) {
                console.log("You do not have access to this Moment");
                history.push('/error', {
                    errorMessage: "You do not have access to this Moment. Please make sure the owner has made this Moment joinable via link in their Moment settings."
                });
            } else {
                response.body.json().then((json) => {
                    console.log(json);
                    // @ts-ignore todo fix later
                    setMomentInfo(json);
                    setIsLoading(false);
                })
            }
        }).catch((error) => {
            console.log("we have an error");
            console.log(error);
        })
    }

    /*
        Check if a login is required, otherwise render the moment
    */
    return (
        <>
            {loginRequired
                ?
                <Redirect to={`/login?redirect=/moments/${id}`} />
                :
                <>
                    {isLoading
                        ?
                        <Stack spacing={1}>
                            <Skeleton variant="rectangular" height={10} />
                            <Skeleton variant="rectangular" height={20} />
                            <Skeleton variant="rectangular" height={50} />
                        </Stack>
                        :
                        <Moment momentInfo={momentInfo} updateMomentCallback={loadMoment} />
                    }
                </>
            }

        </>
    );
}
