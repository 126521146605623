/*
    This component returns the Authenticator component
    and also includes children inside of it (if they need to be guarded
    by the Authenticator)
*/

import React from "react";
import { Authenticator } from '@aws-amplify/ui-react';

// local imports
import PageTitle from '../components/PageTitle';
import './SignInUp.css'; // Import your custom CSS file

// TODO see if children should be any?
export default function SignInUp({ children }: any) {

    return (
        <>
            <PageTitle title={"Login / Signup"} />
            <Authenticator
                initialState={'signUp'}
                loginMechanisms={['email']}
                signUpAttributes={['family_name', 'given_name']}
                formFields={formFields}
            >
                {children}
            </Authenticator>
        </>

    );
}

const formFields = {
    signIn: {
        username: {
            placeholder: 'Enter Your Email Here',
            isRequired: true,
            label: 'Email'
        },
    },
    signUp: {
        email: {
            label: 'Email',
            placeholder: 'Email address',
            isRequired: true,
            order: 1
        },
        password: {
            label: 'Password',
            placeholder: 'Enter your password',
            isRequired: true,
            order: 2,
        },
        confirm_password: {
            label: 'Confirm Password',
            isRequired: true,
            order: 3,
        },
        given_name: {
            label: 'First name',
            placeholder: 'First name',
            isRequired: true,
            order: 4
        },
        family_name: {
            label: 'Last name',
            placeholder: 'Last name',
            isRequired: true,
            order: 5
        }
    },
    confirmSignUp: {
        confirmation_code: {
            label: 'Confirmation Code',
            placeholder: 'Enter your Confirmation Code',
            isRequired: true,
        },
    }
};
