import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import config from './libs/config';

// TODO perhaps move this to App.js instead?
Amplify.configure({
    Auth: {
        Cognito: {
            region: config.cognito.REGION,
            userPoolId: config.cognito.USER_POOL_ID,
            userPoolClientId: config.cognito.APP_CLIENT_ID,
            //identityPoolId: config.cognito.IDENTITY_POOL_ID,
            signUpVerificationMethod: 'code',
        }
    },
    API: {
        REST: {
            'boards': {
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            }
        }
    }
});


ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
