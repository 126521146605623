const config = {
    MAX_ATTACHMENT_SIZE: 1000000000,
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://k0ax4mqvf5.execute-api.ap-southeast-2.amazonaws.com",
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_BoHdAKJxz",
        APP_CLIENT_ID: "5snvsp1nkc12qk54g4i9kq8m10",
        IDENTITY_POOL_ID: "ap-southeast-2:5baeb6f6-21a0-4782-8be2-8e15f18b9fad",
    },
};

export default config;


/* DEV CONFIG
const config = {
    MAX_ATTACHMENT_SIZE: 1000000000,
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://m908g1aqdg.execute-api.ap-southeast-2.amazonaws.com",
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_qqobCl6gz",
        APP_CLIENT_ID: "571cbnmuuus7c50kjjd7pvc8cs",
        IDENTITY_POOL_ID: "ap-southeast-2:dd24b9e3-e15f-4f11-b3fe-c578152dd0bb",
    },
};
*/

/* PROD CONFIG
const config = {
    MAX_ATTACHMENT_SIZE: 1000000000,
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://k0ax4mqvf5.execute-api.ap-southeast-2.amazonaws.com",
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_BoHdAKJxz",
        APP_CLIENT_ID: "5snvsp1nkc12qk54g4i9kq8m10",
        IDENTITY_POOL_ID: "ap-southeast-2:5baeb6f6-21a0-4782-8be2-8e15f18b9fad",
    },
};
*/