/*
    This renders the 'Join Moment' button that is shown on public moments
*/

// React imports
import React, { useEffect, useState } from "react";

// MUI imports
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Typography from "@mui/material/Typography";

// Amplify imports
import { getCurrentUser } from "aws-amplify/auth";

// local imports
import { MomentData } from "../interfaces/interfaces";
import SignInUp from "../pages/SignInUp";
import JoinDialog from "./JoinDialog";


export interface JoinButtonProps {
    momentInfo: MomentData,
    updateMomentCallback(): any,
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function JoinButton({ momentInfo, updateMomentCallback }: JoinButtonProps) {

    const [showModal, setShowModal] = useState(false);
    const [showJoinButtons, setShowJoinButtons] = useState(false);

    async function determineButtonVisibility() {

        // get the current username of the logged in user
        var username: string | undefined = undefined;
        try {
            username = (await getCurrentUser()).username;
            console.log(`JoinButton: Successful call of getCurrentUser(), username is ${username}`);
        } catch (UserUnAuthenticatedException) {
            console.log(`JoinButton: Unsuccessful call of getCurrentUser(), no logged in user`);
            username = undefined;
        }

        console.log(`JoinButton: Contributors is ${momentInfo.info.contributors}`);

        // determine if the button should be shown or not
        // if the user is the owner then don't show the join button
        if (username === momentInfo.info.owner.username) {
            setShowJoinButtons(false);
            // if the user is a contributor then don't show the join button
        } else if (momentInfo.info.contributors.some(user => user.username === username)) {
            setShowJoinButtons(false);
            // if the moment is not joinable (regardless of private or public) don't show them
        } else if (momentInfo.info.joinable == false) {
            setShowJoinButtons(false);
        } else {
            setShowJoinButtons(true);
        }
    }

    // On creation of this component, and anytime the momentInfo is updated, determine if
    // this button should be visible or not
    useEffect(() => {
        determineButtonVisibility();
    }, [momentInfo]);


    if (showJoinButtons) {
        return (
            <>
                <Dialog
                    open={showModal}
                    TransitionComponent={Transition}
                    onClose={() => setShowModal(!showModal)}>
                    <SignInUp>
                        <JoinDialog
                            updateMomentCallback={updateMomentCallback}
                            closeModal={() => setShowModal(false)} />
                    </SignInUp>
                </Dialog >

                {/* Fixed bottom container */}
                <Stack
                    sx={{
                        "borderTopLeftRadius": '20px',
                        "borderTopRightRadius": '20px',
                        "position": "fixed",
                        "bottom": 0,
                        "left": 0,
                        "width": "100%",
                        "backgroundColor": "rgb(237, 224, 235)",
                        "z-index": 500
                    }}
                    spacing={0.5}
                    alignItems="center"
                    justifyContent="center"
                    className="bottom-container">
                    <Button
                        data-testid="join-button"
                        color="success"
                        variant="contained"
                        sx={{ m: 1, mt: 1.5 }}
                        startIcon={<AddIcon />}
                        onClick={() => setShowModal(!showModal)}>
                        Join this Moment!
                    </Button>
                    <Typography
                        sx={{ px: 3, pb: 1, fontSize: 14, textAlign: 'center' }}>
                        Join this Moment to include it to your own personal moments list and add your own photos and videos!
                    </Typography>
                </Stack >


            </>
        )
    } else {
        return (
            <></>
        )
    }
}
