
// React imports
import React from 'react';

// React Router imports
import { useLocation } from 'react-router-dom';

// MUI imports
import Typography from '@mui/material/Typography';

// local imports
import PageTitle from '../components/PageTitle';

export default function Error() {
    const location = useLocation();
    // @ts-ignore todo fix later
    const errorMessage = location.state && location.state.errorMessage;

    return (
        <>
            <PageTitle title={"Error"} />
            <div>
                <Typography variant="h4">Error</Typography>
                {errorMessage && <Typography
                    variant="body1"
                >{errorMessage}</Typography>}
            </div>
        </>

    );
}