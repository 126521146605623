import React from "react";
import { Route } from "react-router-dom";

// local imports
import SignInUp from '../pages/SignInUp';

export default function AuthenticatedRoute({ children, ...rest }) {
    return (
        <Route {...rest}>
            <SignInUp>
                {children}
            </SignInUp>
        </Route>
    );
}