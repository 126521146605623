import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';


function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


export default function UnauthenticatedRoute({ children, ...rest }) {

    const redirect = querystring("redirect");
    console.log(`Redirect value is ${redirect}`);

    // TODO for some reason you must use context.route instead of context.authStatus
    // got to figure out why
    //const { authStatus } = useAuthenticator(context => [context.authStatus]);
    //console.log(`authStatus in UnauthenticatedRoute is ${authStatus}`);

    const { route } = useAuthenticator(context => [context.route]);
    console.log(`route in UnauthenticatedRoute is ${route}`);


    return (
        <Route {...rest}>
            {route === 'authenticated'
                ?
                <Redirect to={redirect === "" || redirect === null ? "/mymoments" : redirect} />
                :
                (children)
            }
        </Route>
    );
}
