import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DateTime } from "luxon";

export default function MomentList(props) {

    const history = useHistory();

    function openMoment(id) {
        history.push("/moments/" + id);
    }

    return (
        <Grid container spacing={4}>
            {props.moments.map(({ id, name, date, secret, givenName, familyName, coverPhoto }) => (
                <Grid item key={id} xs={12} sm={6} md={6} lg={3}>
                    <Card>
                        <CardMedia
                            component="img"
                            height="140"
                            src={coverPhoto}
                            onClick={() => openMoment(id)}
                            sx={{ cursor: "pointer" }}
                        />
                        <CardContent sx={{ pb: 0 }}>
                            <Typography
                                variant="h5"
                                onClick={() => openMoment(id)}
                                sx={{ cursor: "pointer" }}
                            >
                                {name}
                            </Typography>
                            <Grid alignItems='center' container>
                                <Grid item sx={{ pr: 1 }}>
                                    <CalendarMonthOutlinedIcon />
                                </Grid>
                                <Grid>
                                    <Typography color="text.secondary">
                                        {date && DateTime.fromISO(date).toFormat("ccc d LLLL y")}
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Grid alignItems='center' container sx={{ pt: 1, pb: 1 }}>
                                <Grid item sx={{ pr: 0.5 }}>
                                    {secret == 0 &&
                                        <Tooltip title="This Moment is public, anyone in the world can view it" enterTouchDelay={0} leaveTouchDelay={2000}>
                                            <LockOpenOutlinedIcon fontSize="medium" color="success" />
                                        </Tooltip>
                                    }
                                    {secret == 1 &&
                                        <Tooltip title="This Moment is secret, only you and the people added to it can see it" enterTouchDelay={0} leaveTouchDelay={2000}>
                                            <LockOutlinedIcon color="error" />
                                        </Tooltip>
                                    }
                                </Grid>
                                <Grid>
                                    <Chip
                                        label={givenName + " " + familyName}
                                        variant="outlined"
                                        sx={{ mt: 0, mb: 0 }}
                                    />
                                </Grid>
                            </Grid>

                        </CardContent>
                        <CardActions sx={{ pl: 2, pb: 2 }}>
                            <Button
                                variant="contained"
                                color="vestij"
                                size="small"
                                onClick={() => openMoment(id)}
                            >
                                Open
                            </Button>
                            {/*<Button variant="contained" color="vestij" size="small">Copy Link</Button>*/}
                        </CardActions>
                    </Card>
                </Grid>
            ))
            }
        </Grid >
    );
}