import React from 'react';
import "./NotFound.css";
import PageTitle from '../components/PageTitle';

export default function NotFound() {
    return (
        <>
            <PageTitle title={"Not found"} />
            <div className="NotFound text-center">
                <h3>Sorry, page not found!</h3>
            </div>
        </>


    );
}