import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { signOut } from "aws-amplify/auth";
import { useHistory } from "react-router-dom";



export default function CustomNavbar() {
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const history = useHistory();

    async function handleLogout() {
        await signOut();
        history.push("/login"); // Send the user to the login page
    }

    // Construct the image source for the navbar icon
    // dynamically based on the current website's root URL
    const imagePath = '/android-chrome-192x192.png';
    const rootURL = window.location.origin;
    const imageURL = rootURL + imagePath;

    return (
        <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
            <LinkContainer to="/">
                <Navbar.Brand href="/" className="font-weight-bold text-muted">
                    <img
                        alt=""
                        src={imageURL}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    Vestij
                </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
                <Nav activeKey={window.location.pathname}>
                    <LinkContainer to="/">
                        <Nav.Link>Home</Nav.Link>
                    </LinkContainer>
                    {
                        authStatus === 'authenticated'
                            ?
                            (
                                <>
                                    <LinkContainer to="/public">
                                        <Nav.Link>Public Moments</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/mymoments">
                                        <Nav.Link>My Moments</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/settings">
                                        <Nav.Link>Settings</Nav.Link>
                                    </LinkContainer>
                                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                                </>
                            )
                            :
                            (
                                <>
                                    <LinkContainer to="/public">
                                        <Nav.Link>Public Moments</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/signup">
                                        <Nav.Link>Sign Up</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/login">
                                        <Nav.Link>Login</Nav.Link>
                                    </LinkContainer>
                                </>
                            )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}