import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import "./NewMoment.css";
import { post } from "aws-amplify/api";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";


import TextField from '@mui/material/TextField';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Typography from '@mui/material/Typography';
import PageTitle from '../components/PageTitle';


export default function NewMoment() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [isSecret, setIsSecret] = useState(0);
    const [isJoinable, setIsJoinable] = useState(1);
    const [momentDate, setMomentDate] = useState(new Date());

    function validateForm() {
        return name.length > 0 && momentDate != null;
    }


    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);
        const isSecret = 1;

        try {
            const momentID = await createMoment();
            history.push(`${momentID}`);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function createMoment() {

        console.log("momentData is ");
        console.log(momentDate);

        const { username } = await getCurrentUser();
        const { tokens } = await fetchAuthSession();

        const input = {
            apiName: 'boards',
            path: '/board',
            options: {
                headers: {
                    Authorization: tokens.idToken,
                    cognitoUsername: username
                },
                body: {
                    "name": name,
                    "secret": isSecret,
                    "joinable": isJoinable,
                    "date": momentDate.toISOString().slice(0, 19).replace(/T/g, " ") // convert date to UTC and YYYY-MM-DD hh:mm:ss format for MySQL
                },
            }
        }
        const { body } = await post(input).response;
        const momentID = await body.json();
        return momentID;
    }

    return (
        <div className="NewMoment">
            <PageTitle title={"New Moment"} />
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="content">
                    <Form.Label>
                        Moment Name
                    </Form.Label>
                    <Form.Control
                        value={name}
                        placeholder="Your moment name..."
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Visibility
                    </Form.Label>

                    <Grid alignItems='center' container sx={{ pb: 1 }}>
                        <Grid item sx={{ pr: 1 }}>
                            <Form.Check
                                type="radio"
                                id="public-radio"
                                label="Public"
                                checked={!isSecret}
                                onChange={(e) => setIsSecret(0)}
                            />
                        </Grid>
                        <Grid item sx={{ pr: 1 }}>
                            <LockOpenOutlinedIcon color="success" />
                        </Grid>
                        <Grid item>
                            <Typography
                                htmlFor="public-radio"
                            >
                                (Anyone in the world can view your moment)
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid alignItems='center' container sx={{ pb: 2 }}>
                        <Grid item sx={{ pr: 1 }}>
                            <Form.Check
                                type="radio"
                                id="secret-radio"
                                label="Secret"
                                checked={isSecret}
                                onChange={(e) => setIsSecret(1)}
                            />
                        </Grid>
                        <Grid item sx={{ pr: 1 }}>
                            <LockOutlinedIcon color="error" />
                        </Grid>
                        <Grid item>
                            <Typography
                                htmlFor="secret-radio"
                            >
                                (Only you and people you add can see this moment)
                            </Typography>
                        </Grid>
                    </Grid>
                </Form.Group>
                <Form.Group>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            inputFormat="dd/MM/yyyy hh:mm a"
                            label="Select Date & Time"
                            value={momentDate}
                            onChange={(date) => {
                                setMomentDate(date.toJSDate());
                            }}
                        />
                    </LocalizationProvider>
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        type="checkbox"
                        id="checkbox"
                        label="Allow people to view and join via URL"
                        checked={isJoinable}
                        onChange={() => setIsJoinable(!isJoinable)}
                    />
                </Form.Group>
                <Typography align="center" sx={{ mt: 2, pb: 1 }}>
                    You can change these settings later on
                </Typography>
                <LoaderButton
                    block
                    type="submit"
                    size="lg"
                    variant="success"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Create Moment
                </LoaderButton>
            </Form>
        </div>
    );
}