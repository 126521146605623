// react imports
import React, { useState, useEffect } from "react";

// bootstrap imports
import Alert from "react-bootstrap/Alert";

//mui imports
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

// import interfaces
import { S3File } from "../interfaces/interfaces";

// import API and Cognito functions from the aws-amplify library
// the return types are imported from the amazon-cognito-identity-js library
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { post } from "aws-amplify/api";

const config = require('../libs/config');
const axios = require('axios').default;


// An interface to track the upload of each individual file
interface fileUploadProgress {
    "filename": string
    "progress": number,
    "status": string,
    "bytesUploaded": number,
    "bytesToUpload": number
    "errorMessage": string
}

interface UploadComponentProps {
    "files": FileList,
    "signedURLs": S3File[],
    "id": string
}

export default function UploadComponent(props: UploadComponentProps) {

    const [progress, setProgress] = useState<fileUploadProgress[]>([]);
    const [statusMessage, setStatusMessage] = useState<string>('');
    const [showStatusMessage, setShowStatusMessage] = useState<boolean>(false);

    // once this component loads we want to start uploading the files
    useEffect(() => {
        uploadFiles();
    }, [])

    async function uploadFiles() {
        // Build up the progress array
        const files: FileList = props.files;
        Array.from(files).forEach((file: File, index: number) => {
            var tempProgress: fileUploadProgress[] = progress;
            tempProgress[index] = {
                "filename": file.name,
                "progress": 0,
                "status": "uploading",
                "bytesUploaded": 0,
                "bytesToUpload": file.size,
                "errorMessage": ""
            };
            setProgress(tempProgress);
        });

        setStatusMessage("Preparing to upload files...");
        setShowStatusMessage(true);

        // set the state up ready for the upload
        setProgress([]);


        /*
            Prepare to get pre-signed URL's
        */
        var filenameArray = [];
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            if (file && file.size > config.MAX_ATTACHMENT_SIZE) {
                alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000} MB. Current file size is ${file.size / 1000000} MB`);
                return;
            } else {
                filenameArray.push({ "name": file.name, "type": file.type, "size": file.size });
            }
        };
        console.log("filenameArray is:");
        console.log(filenameArray);


        const { username } = await getCurrentUser();
        const { tokens } = await fetchAuthSession();
        const idString = tokens?.idToken?.toString ?? "Unknown";

        if (username === undefined) {
            console.log("Username is undefined");
            return;
        }

        // TODO secure this API call
        // get a list of pre-signed URL's to PUT
        var returned_urls: string[] = [];
        const input = {
            apiName: 'boards',
            path: `/signedURL/${props.id}`,
            options: {
                headers: {
                    'cognitousername': username,
                },
                body: {
                    'filenames': filenameArray,
                },
            }
        }
        const { body } = await post(input).response;
        // @ts-ignore todo fix later
        returned_urls = await body.json();
        console.log("Returned URL's are:");
        console.log(returned_urls);


        Array.from(files).forEach((file: File, index: number) => {
            console.log("About to upload " + file.name);


            // check the filename and see if it's already been uploaded
            if (props.signedURLs.find(e => e.fileName === file.name) !== undefined) {
                console.log(`${file.name} already uploaded`);
                let someProgress: fileUploadProgress[] = [...progress];
                someProgress[index].status = "done";
                someProgress[index].progress = 100;
                someProgress[index].bytesUploaded = someProgress[index].bytesToUpload;
                someProgress[index].errorMessage = "File previously uploaded, skipping";
                setProgress(someProgress);
                return;
            }


            // if file hasn't already been uploaded then upload the file
            axios(
                {
                    method: 'put',
                    url: returned_urls[index],
                    headers: {
                        'Content-Type': file.type,
                        'Content-Disposition': 'attachment' // this ensures that in the MediaModal it downloads as an attachment, rather than opening in the browser
                    },
                    data: file, // need to set data as empty otherwise Content-Type doesn't work
                    onUploadProgress: (event: any) => {
                        console.log(`Progress event for file ${file.name}`);
                        console.log(event);
                        console.log("Progress is");
                        console.log(progress);
                        if (event.lengthComputable) {
                            var tmpProgress: fileUploadProgress[] = progress;
                            console.log(`Total event bytes for file ${file.name} at index ${index} is ${event.total}`);
                            console.log(`Total event bytes loaded for file ${file.name} at index ${index} is ${event.loaded}`);
                            console.log("Progress for " + index + " is " + Math.round((event.loaded / event.total) * 100));
                            tmpProgress[index].progress = Math.round((event.loaded / event.total) * 100);
                            tmpProgress[index].bytesUploaded = event.loaded;
                            setProgress(tmpProgress.slice()); // slice() is important as it creates a shallow copy, otherwise we'd just be setting a reference to the array              
                        }
                    }
                }
            ).then((response: any) => {
                console.log(`Have finished the request for ${file.name}`);
                console.log(response);
                let someProgress: fileUploadProgress[] = [...progress];
                someProgress[index].status = "done";
                setProgress(someProgress);
                // check if all files have uploaded
                if (progress.every(e => e.status === "done")) {
                    setStatusMessage("All files uploaded, please refresh the page to see them! If any uploads failed you can easily re-select all the same photos and videos as Vestij is smart enough to skip the ones that have already been uploaded.");
                }

            }).catch((error: Error) => {
                console.log(`Error for ${file.name}`);
                console.log(error);
                let someProgress: fileUploadProgress[] = [...progress];
                someProgress[index].status = "error";
                someProgress[index].errorMessage = error.message;
                setProgress(someProgress);
            });
        });
    }

    function renderStatusMessage() {
        if (showStatusMessage) {
            return (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mt: 2 }}>
                    <Grid item>
                        <Alert variant="success" onClose={() => setShowStatusMessage(false)} className="text-center" dismissible >
                            {statusMessage}
                        </Alert>

                    </Grid>
                </Grid>
            )
        } else {
            return (
                <></>
            );
        }
    }

    /* 
        Returns the React components for the loading bars for each file
    */
    function renderLoadBars() {
        // sum all the progress percentages and then divide by the total amount
        if (progress.length === 0) {
            return;
        } else {
            console.log("Progress array is:");
            console.log(progress);
            return (
                <Accordion sx={{ mb: 2, mt: 1 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container>
                            <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <Paper sx={{ p: 2, bgcolor: "green", color: "white", textAlign: "center" }}>
                                    {progress.filter(e => e.status === "done").length} uploads completed
                                </Paper>
                            </Grid >
                            <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <Paper sx={{ p: 2, bgcolor: "orange", color: "white", textAlign: "center" }}>
                                    {progress.filter(e => e.status === "uploading").length} uploads in progress
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <Paper sx={{ p: 2, bgcolor: "red", color: "white", textAlign: "center" }}>
                                    {progress.filter(e => e.status === "error").length} uploads failed
                                </Paper>
                            </Grid >
                        </Grid >
                    </AccordionSummary>
                    <AccordionDetails>
                        <>
                            {
                                progress.map((element, index) => (
                                    <>
                                        <Paper elevation={12} sx={{ p: 2, m: 2 }}>
                                            <p key="{element.filename}}">{element.filename}</p>
                                            {element.progress < 100 ?
                                                (
                                                    <>
                                                        <p>Uploading... {element.progress}%</p>
                                                        <p>{element.bytesUploaded} / {element.bytesToUpload} bytes</p>
                                                        <LinearProgress key={index} variant="determinate" value={element.progress} />
                                                    </>
                                                ) : null}
                                            {element.progress === 100
                                                && element.status === "uploading" ?
                                                (
                                                    <>
                                                        <CircularProgress />
                                                        <p>Processing...</p>
                                                        <LinearProgress key={index} variant="determinate" value={element.progress} />
                                                    </>
                                                ) : null}
                                            {element.progress === 100
                                                && element.status === "done" ?
                                                (
                                                    <>
                                                        <p>Done!</p>
                                                        <p>{element.errorMessage}</p>
                                                        <LinearProgress key={index} variant="determinate" value={element.progress} />
                                                    </>
                                                ) : null}
                                            {element.status === "error" &&
                                                (
                                                    <>
                                                        <p>Error!</p>
                                                        <p>{element.errorMessage}</p>
                                                    </>
                                                )}
                                        </Paper>
                                    </>
                                ))
                            }
                        </>
                    </AccordionDetails>
                </Accordion>
            )
        }
    }

    async function getIdToken() {
        try {
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            return idToken
        } catch (err) {
            console.log(err);
        }
    }

    async function getCurrentUsername() {
        try {
            const { username, userId, signInDetails } = await getCurrentUser();
            console.log(`Cognito username is ${username}}`);
            return username;
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            {renderStatusMessage()}
            {renderLoadBars()}
        </>
    )



}