import React from 'react';
import { Route, Switch } from "react-router-dom";

// local imports
import PublicMoments from "./pages/PublicMoments";
import NotFound from "./pages/NotFound";
import SignInUp from "./pages/SignInUp";
import NewBoard from "./pages/NewMoment";
import Settings from "./pages/Settings";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import MyBoards from "./pages/MyMoment";
import MomentSecurityWrapper from "./pages/MomentSecurityWrapper";
import Home from "./pages/Home";
import Error from "./pages/Error";

/* 
    AuthenticatedRoute is for components that you MUST be logged in for
    UnauthenticatedRoute is for components that you CANNOT access when you're logged in
    All other Routes can be accessed regardless if you're logged in or not
*/

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/public">
                <PublicMoments />
            </Route>
            <AuthenticatedRoute exact path="/mymoments">
                <MyBoards />
            </AuthenticatedRoute>
            <UnauthenticatedRoute exact path="/login">
                <SignInUp />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/signup">
                <SignInUp />
            </UnauthenticatedRoute>
            <AuthenticatedRoute exact path="/settings">
                <Settings />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/moments/new">
                <NewBoard />
            </AuthenticatedRoute>
            <Route exact path="/moments/:id">
                <MomentSecurityWrapper />
            </Route>
            <Route exact path="/error">
                <Error />
            </Route>
            {/* Finally, catch all unmatched routes */}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    )
}