/*
    This component is what the masonry is comprised of.
    It renders the image plus the name in the bottom left corner.
*/

// React imports
import React from "react";

// MUI imports
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Tooltip from '@mui/material/Tooltip';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';

// local imports
import { S3File } from "../interfaces/interfaces";
import { acceptedVideoFileTypes } from "../libs/vars";

// We must use index, width and data as these are the props required by the Masonry component
interface FileDisplayProps {
    index: number,
    width: number
    data: S3File,
    imageClicked: (file: S3File) => void;
}

export default function FileDisplay(props: FileDisplayProps) {

    const videoPlayFontSize = props.width * 0.3;

    const style: React.CSSProperties = {
        position: 'absolute',
        left: 8,
        bottom: 8,
        userSelect: "none"
    }

    const iconStyle: React.CSSProperties = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white', // Customize the color of the play icon
        fontSize: `${videoPlayFontSize}`, // Customize the size of the play icon
        opacity: 0.7, // Customize the opacity of the play icon
        cursor: 'pointer', // Add cursor pointer to indicate clickability
    };

    return (
        <Card
            key={props.data.fileName}
            style={{
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                position: 'relative',
                cursor: 'pointer'
            }}
        >
            <CardMedia
                component="img"
                image={props.data.compressedSignedURL}
                loading="lazy"
                onClick={() => {
                    console.log("Show picture in modal");
                    console.log(props.data);
                    props.imageClicked(props.data);
                }}
            />

            {acceptedVideoFileTypes.includes(props.data.fileType) &&
                <PlayCircleOutline
                    style={iconStyle}
                    onClick={() => {
                        console.log("Show picture in modal");
                        console.log(props.data);
                        props.imageClicked(props.data);
                    }}
                />
            }

            {
                props.width > 150
                &&
                <Tooltip title={`${props.data.owner.givenName} uploaded this`} enterTouchDelay={0} leaveTouchDelay={2000}>
                    <Chip
                        sx={{
                            background: 'rgba(255, 255, 255, 0.8)',
                            padding: '8px',
                        }}
                        style={style}
                        label={`${props.data.owner.givenName}`}
                    />
                </Tooltip>
            }

        </Card >
    )
}