/*
    This component renders the owner chip at the top of the Moment
*/

// React imports
import React from "react";

// MUI imports
import Grid from '@mui/material/Grid';

// local imports
import ContributorChip from "./ContributorChip";

interface OwnerChipProps {
    name: string,
    numPhotos: number,
    numVideos: number
}

export default function OwnerChip(props: OwnerChipProps) {

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 3 }}>
            <Grid item>
                <ContributorChip
                    name={props.name}
                    numPhotos={props.numPhotos}
                    numVideos={props.numVideos}
                />
            </Grid>
        </Grid>
    )
}
