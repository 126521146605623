/*
    This component renders the Masonry object as well as the
    column toggle button group at the top of the Masonry
*/

// React imports
import React, { useState, useCallback } from 'react';

// MUI imports
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// window imports
import { useWindowSize } from "@react-hook/window-size";

// Masonry imports
import {
    useMasonry,
    usePositioner,
    useContainerPosition,
    useScroller,
    useResizeObserver
} from "masonic";

// local imports
import { S3File } from "../interfaces/interfaces";
import FileDisplay from "../components/FileDisplay";

interface CustomMasonryProps {
    files: S3File[],
    imageClicked: (index: number) => void,
}

export default function CustomMasonry({ files, imageClicked }: CustomMasonryProps) {

    // determines the number of columns in the masonry
    const [numColumns, setNumColumns] = useState<number>(2);

    // these are required components for the Masonry
    // we use useMasonry so that our Masonry re-renders on a change
    // more details here: https://github.com/jaredLunde/masonic/issues/12
    const containerRef = React.useRef(null);
    const [windowWidth, windowHeight] = useWindowSize();
    const { offset, width } = useContainerPosition(containerRef, [
        windowWidth,
        windowHeight,
    ]);

    const { scrollTop, isScrolling } = useScroller(offset);

    const positioner = usePositioner({
        width,
        columnCount: numColumns,
        columnGutter: 10,
        rowGutter: 10
    }, files);

    const resizeObserver = useResizeObserver(positioner);


    function updateNumColumns(event: React.MouseEvent<HTMLElement>, newColumns: number) {
        if (newColumns !== null) {
            setNumColumns(newColumns);
        }
    }

    // wrap the FileDisplay componet
    const FileDisplayMasonry = useCallback(
        props =>
            <FileDisplay
                {...props}
                imageClicked={() => imageClicked(props.index)}
            />,
        [imageClicked]
    );

    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                sx={{ mb: 2, mt: 2 }}
            >
                <ToggleButtonGroup
                    color="primary"
                    value={numColumns}
                    exclusive
                    onChange={updateNumColumns}
                    sx={{ '& .MuiToggleButton-root:focus': { outline: 'none' } }} // Apply CSS rule inline
                >
                    <ToggleButton value={1}>
                        {SingleColumnIcon()}
                    </ToggleButton>
                    <ToggleButton value={2}>
                        {TwoColumnsIcon()}
                    </ToggleButton>
                    <ToggleButton value={3}>
                        {ThreeColumnsIcon()}
                    </ToggleButton>
                    <ToggleButton value={5}>
                        {FiveColumnsIcon()}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Box>
                {useMasonry({
                    positioner,
                    scrollTop,
                    isScrolling,
                    resizeObserver,
                    height: windowHeight,
                    containerRef,
                    items: files,
                    overscanBy: 2,
                    render: FileDisplayMasonry,
                    style: { 'outline': 'none' }
                })}
            </Box>
        </>

    )
}

// renders an SVG
function SingleColumnIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 24 24"
        >
            <rect x="6.5625" y="0" width="10.875" height="10.875" fill="#1976d2" />

            <rect x="6.5625" y="13.125" width="10.875" height="10.875" fill="#1976d2" />
        </svg>
    );
}

// renders an SVG
function TwoColumnsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 24 24"
        >
            <rect x="0" y="0" width="10" height="10" fill="#1976d2" />
            <rect x="14" y="0" width="10" height="10" fill="#1976d2" />

            <rect x="0" y="14" width="10" height="10" fill="#1976d2" />
            <rect x="14" y="14" width="10" height="10" fill="#1976d2" />
        </svg>
    );
}

// renders an SVG
function ThreeColumnsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 24 24"
        >
            <rect x="0" width="6.5" height="6.5" fill="#1976d2" />
            <rect x="8.75" width="6.5" height="6.5" fill="#1976d2" />
            <rect x="17.5" width="6.5" height="6.5" fill="#1976d2" />

            <rect x="0" y="8.75" width="6.5" height="6.5" fill="#1976d2" />
            <rect x="8.75" y="8.75" width="6.5" height="6.5" fill="#1976d2" />
            <rect x="17.5" y="8.75" width="6.5" height="6.5" fill="#1976d2" />

            <rect x="0" y="17.5" width="6.5" height="6.5" fill="#1976d2" />
            <rect x="8.75" y="17.5" width="6.5" height="6.5" fill="#1976d2" />
            <rect x="17.5" y="17.5" width="6.5" height="6.5" fill="#1976d2" />
        </svg>
    );
}

// renders an SVG
function FiveColumnsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 24 24"
        >
            <rect x="0" width="3" height="3" fill="#1976d2" />
            <rect x="5.25" width="3" height="3" fill="#1976d2" />
            <rect x="10.5" width="3" height="3" fill="#1976d2" />
            <rect x="15.75" width="3" height="3" fill="#1976d2" />
            <rect x="21" width="3" height="3" fill="#1976d2" />

            <rect x="0" y="5.25" width="3" height="3" fill="#1976d2" />
            <rect x="5.25" y="5.25" width="3" height="3" fill="#1976d2" />
            <rect x="10.5" y="5.25" width="3" height="3" fill="#1976d2" />
            <rect x="15.75" y="5.25" width="3" height="3" fill="#1976d2" />
            <rect x="21" y="5.25" width="3" height="3" fill="#1976d2" />

            <rect x="0" y="10.5" width="3" height="3" fill="#1976d2" />
            <rect x="5.25" y="10.5" width="3" height="3" fill="#1976d2" />
            <rect x="10.5" y="10.5" width="3" height="3" fill="#1976d2" />
            <rect x="15.75" y="10.5" width="3" height="3" fill="#1976d2" />
            <rect x="21" y="10.5" width="3" height="3" fill="#1976d2" />

            <rect x="0" y="15.75" width="3" height="3" fill="#1976d2" />
            <rect x="5.25" y="15.75" width="3" height="3" fill="#1976d2" />
            <rect x="10.5" y="15.75" width="3" height="3" fill="#1976d2" />
            <rect x="15.75" y="15.75" width="3" height="3" fill="#1976d2" />
            <rect x="21" y="15.75" width="3" height="3" fill="#1976d2" />

            <rect x="0" y="21" width="3" height="3" fill="#1976d2" />
            <rect x="5.25" y="21" width="3" height="3" fill="#1976d2" />
            <rect x="10.5" y="21" width="3" height="3" fill="#1976d2" />
            <rect x="15.75" y="21" width="3" height="3" fill="#1976d2" />
            <rect x="21" y="21" width="3" height="3" fill="#1976d2" />
        </svg>
    );
}