/*
This component is rendered when a user clicks on a picture/video, displaying
the picture or video in a modal and with a variety of options
*/

// React imports
import React, { useState, useEffect } from "react";

// MUI imports
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

// Swipeable imports
import { useSwipeable } from 'react-swipeable';

// local imports
import { acceptedPhotoFileTypes, acceptedVideoFileTypes } from "../libs/vars";
import { S3File } from "../interfaces/interfaces";

// Amplify imports
import { getCurrentUser } from 'aws-amplify/auth';
import { del } from "aws-amplify/api";


interface Prop {
    setFocusedFileIndex: Function,
    file: S3File,
    updateMomentCallback: Function,
    updateFocusedFileCallback: Function
}

export default function MediaModal(props: Prop) {

    const [currentUsername, setCurrentUsername] = useState<string | undefined>(undefined);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
    const [openInfoToolTip, setOpenInfoToolTip] = React.useState(false);

    async function updateCurrentUsername() {
        try {
            const { username } = await getCurrentUser();
            console.log(`Successful call of getCurrentUser(), username is ${username}`);
            setCurrentUsername(username);
        } catch (UserUnAuthenticatedException) {
            console.log(`Unsuccessful call of getCurrentUser(), no longed in user`);
            setCurrentUsername(undefined);
        }
    }

    // Update the current username on component load
    // We use useEffect() as this is effectively calling an 'external system'
    useEffect(() => {
        updateCurrentUsername();
    }, []);

    // Setup listeners to handle left and right arrow key presses
    useEffect(() => {
        // listen for key presses
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'ArrowLeft') {
                props.updateFocusedFileCallback(-1);
            } else if (event.key === 'ArrowRight') {
                props.updateFocusedFileCallback(1);
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [props.updateFocusedFileCallback]);

    function handleModalClose() {
        props.setFocusedFileIndex(null);
    };

    async function handleDeleteClick() {
        setDeleteInProgress(true);
        await deleteFile();
        props.updateMomentCallback();
        handleDeletePopupClose();
        setDeleteInProgress(false);
    }

    // call an API to delete the file
    async function deleteFile() {
        // @ts-ignore todo fix later
        const cleanedFilename: string = props.file.uncompressedSignedURL.match(/https:\/\/[a-z\-A-Z\.0-9]*\/(.*)\?/)?.[1];
        console.log(`Cleaned file name is ${cleanedFilename}`);

        if (currentUsername === undefined) {
            console.log("Username is undefined");
            return;
        }

        const input = {
            apiName: 'boards',
            path: '/file',
            options: {
                headers: {
                    "cognitousername": currentUsername,
                },
                queryParams: {
                    'filename': cleanedFilename
                },
            }
        }
        var result = await del(input).response;
        console.log(result);
    }

    const modalStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const mediaStyle = {
        maxWidth: '100%',
        maxHeight: '90%',
        //'objectFit': 'contain', // Ensures the image is never cropped
    };

    const stackStyle = {
        width: '90%',
        height: '90%',
        outline: 'none' // get rid of the outline that occurs when the modal is focused
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => props.updateFocusedFileCallback(1),
        onSwipedRight: () => props.updateFocusedFileCallback(-1),
    });

    const handleDeleteIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // @ts-ignore todo fix later
        setAnchorEl(event.currentTarget);
    };

    const handleDeletePopupClose = () => {
        setAnchorEl(null);
    };

    function downloadFile(url: string) {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        // Clean up
        document.body.removeChild(link);
    }

    const handleDownloadButtonClick = () => {
        const fileUrl = props.file.uncompressedSignedURL;
        downloadFile(fileUrl);
    };

    const handleTooltipClose = () => {
        setOpenInfoToolTip(false);
    };

    const handleTooltipOpen = () => {
        setOpenInfoToolTip(true);
        setTimeout(() => {
            setOpenInfoToolTip(false);
        }, 2000);
    };

    function getInitials(word1: string, word2: string) {
        return `${word1[0]}${word2[0]}`
    };

    return (
        <Modal open={true} style={modalStyle} onClose={handleModalClose}>

            <Stack
                {...handlers}
                spacing={1}
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={stackStyle}>

                {acceptedPhotoFileTypes.includes(props.file.fileType)
                    &&
                    <img src={props.file.compressedSignedURL} style={mediaStyle} />
                }
                {acceptedVideoFileTypes.includes(props.file.fileType)
                    &&
                    <video
                        style={mediaStyle}
                        controls
                        preload="auto"
                        poster={props.file.compressedSignedURL}
                        src={props.file.uncompressedSignedURL}
                    />
                }
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{ py: 1, px: 3 }}
                    style={{
                        backgroundColor: '#FFD4F0',
                        borderRadius: 20,
                    }}>
                    <IconButton
                        onClick={() => { props.updateFocusedFileCallback(-1) }}>
                        <ArrowBackIosNewIcon />
                    </IconButton>

                    {currentUsername === props.file.owner.username
                        &&
                        <IconButton
                            color="error"
                            aria-controls="menu"
                            aria-haspopup="true"
                            onClick={handleDeleteIconClick}>
                            <DeleteIcon />
                        </IconButton>}
                    <Menu
                        id="menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleDeletePopupClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <MenuItem sx={{ color: 'red' }} onClick={handleDeleteClick}>
                            {
                                deleteInProgress
                                    ?
                                    <>
                                        <CircularProgress color="error" size={16} sx={{ mr: 2 }} />
                                        Deleting....
                                    </>
                                    :
                                    <>
                                        Confirm delete
                                    </>
                            }

                        </MenuItem>
                        <MenuItem onClick={handleDeletePopupClose}>Cancel</MenuItem>
                    </Menu>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={openInfoToolTip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="top"
                            sx={{ fontSize: 18 }}
                            title={`Uploaded by ${props.file.owner.givenName} ${props.file.owner.familyName}`}
                        >
                            <Avatar
                                onMouseEnter={handleTooltipOpen}
                                onMouseLeave={handleTooltipClose}
                                onClick={handleTooltipOpen}
                                sx={{
                                    bgcolor: '#FFF9D4',
                                    color: '#d111a1',
                                    fontWeight: 'bold',
                                    letterSpacing: '1px',
                                    fontSize: 16,
                                    width: 30,
                                    height: 30,
                                }}>
                                {getInitials(props.file.owner.givenName, props.file.owner.familyName)}
                            </Avatar>
                        </Tooltip>
                    </ClickAwayListener>

                    <IconButton color="primary" onClick={handleDownloadButtonClick}>
                        <DownloadIcon />
                    </IconButton>

                    <IconButton onClick={handleModalClose}>
                        <CloseIcon />
                    </IconButton>

                    <IconButton onClick={() => { props.updateFocusedFileCallback(1) }}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </Modal >
    );
}
