// React imports
import React, { useState, useEffect } from 'react';

// Amplify imports
import { get } from "aws-amplify/api";

//local imports
import MomentList from "../components/MomentList";
import PageTitle from '../components/PageTitle';
import "./PublicMoments.css";
import { onError } from "../libs/errorLib";

// MUI imports
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';

// other imports
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";


export default function PublicMoments() {


    const [publicMoments, setPublicMoments] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const history = useHistory();


    useEffect(() => {
        async function onLoad() {

            try {
                setLoading(true);
                console.log("loading public moments");
                const publicMoments = await loadPublicMoments();
                publicMoments.sort(function (a, b) {
                    // this is to account if dates are NULL (TODO change if they become mandatory)
                    if (a.date === null && b.date === null) { return 0 }
                    if (a.date === null) { return 1; }
                    if (b.date === null) { return -1; }

                    // otherwise if both dates are valid, compare
                    const dateA = DateTime.fromISO(a.date);
                    const dateB = DateTime.fromISO(b.date);
                    if (dateA < dateB) { return 1; }
                    if (dateA > dateB) { return -1; }
                    return 0; // dates must be equal
                });
                setLoading(false);
                setPublicMoments(publicMoments);
                console.log(publicMoments);
            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, []);


    async function loadPublicMoments() {
        const apiCall = get({
            apiName: 'boards',
            path: '/publicBoard',
        });
        const { body } = await apiCall.response;
        const json = await body.json();
        console.log(json);
        return json;
    }

    return (
        <>
            <PageTitle title={"Public Moments"} />
            <div className="home">
                <Stack spacing={1}
                    sx={{ mb: 2 }}
                    alignItems="center"
                    justifyContent="center">
                    <Typography
                        variant="h4"
                        align="center">
                        All Public Moments
                    </Typography>
                    <Button
                        color="success"
                        variant="contained"
                        onClick={() => history.push('/moments/new')}>
                        Create your own moment!
                    </Button>
                </Stack >

                {
                    isLoading ?
                        (
                            <Stack spacing={1} >
                                <Skeleton variant="rectangular" height={10} />
                                <Skeleton variant="rectangular" height={20} />
                                <Skeleton variant="rectangular" height={50} />
                            </Stack>
                        ) : (
                            <>
                                <MomentList
                                    moments={publicMoments}>
                                </MomentList>
                            </>
                        )
                }
            </div >
        </>
    )
}
