/*
    This component displays a chip showing the number of photos and videos uploaded
*/

// React imports
import React from "react";

// MUI imports
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import VideocamIcon from '@mui/icons-material/Videocam';

interface MediaCountProps {
    numPhotos: number,
    numVideos: number
}

export default function MediaCount(props: MediaCountProps) {

    return (
        <Grid
            alignItems='center'
            justifyContent="center"
            container
            sx={{ pt: 0, pb: 2 }}>
            <Grid
                item
                sx={{ pl: 0.5, pr: 0.5 }}>
                <Chip
                    variant="outlined"
                    color="info"
                    sx={{
                        fontSize: 14,
                        "& .MuiChip-label": {
                            'paddingLeft': '8px',
                            'paddingRight': '8px'
                        }
                    }}
                    label={
                        <Stack direction="row" spacing={0.6} alignItems="center">
                            <PhotoCameraIcon color="info" fontSize="small" />
                            <Typography>
                                {props.numPhotos}
                                {
                                    props.numPhotos === 1
                                        ?
                                        (' photo')
                                        :
                                        (' photos')
                                }
                            </Typography>

                            <Divider orientation="vertical" flexItem />

                            <VideocamIcon color="info" fontSize="medium" />
                            <Typography>
                                {props.numVideos}
                                {
                                    props.numVideos === 1
                                        ?
                                        (' video')
                                        :
                                        (' videos')
                                }
                            </Typography>
                        </Stack>
                    }
                />
            </Grid>
        </Grid>
    )
}
