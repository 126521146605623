import React, { useEffect } from 'react';

function PageTitle({ title }: { title: string }) {
    useEffect(() => {
        document.title = title;
    }, [title]);

    return null; // This component doesn't render anything to the DOM
}

export default PageTitle;