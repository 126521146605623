// React imports
import React, { useState, useEffect } from 'react';

// Bootstrap imports
import ListGroup from "react-bootstrap/ListGroup";
import { LinkContainer } from "react-router-bootstrap";

// Amplify imports
import { get } from "aws-amplify/api";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";

// local imports
import MomentList from "../components/MomentList";
import PageTitle from '../components/PageTitle';
import "./PublicMoments.css";
import { onError } from "../libs/errorLib";

// MUI imports
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";

// other imports
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";

export default function MyMoments() {
    const [moments, setMoments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();


    useEffect(() => {
        onLoad();

        async function onLoad() {
            try {
                setIsLoading(true);
                const moments = await loadMoments();
                // sort by date descending (with undated at the bottom)
                moments.sort(function (a, b) {

                    // this is to account if dates are NULL (TODO change if they become mandatory)
                    if (a.date === null && b.date === null) { return 0 }
                    if (a.date === null) { return 1; }
                    if (b.date === null) { return -1; }

                    // otherwise if both dates are valid, compare
                    const dateA = DateTime.fromISO(a.date);
                    const dateB = DateTime.fromISO(b.date);
                    if (dateA < dateB) { return 1; }
                    if (dateA > dateB) { return -1; }
                    return 0; // dates must be equal
                });
                setIsLoading(false);
                setMoments(moments);
            } catch (e) {
                onError(e);
            }
        }
    }, []);


    async function loadMoments() {
        const { username } = await getCurrentUser();
        const { tokens } = await fetchAuthSession();
        const apiCall = get({
            apiName: 'boards',
            path: `/board`,
            options: {
                headers: {
                    Authorization: tokens.idToken,
                    "cognitousername": username
                }
            }
        })
        const { body } = await apiCall.response;
        const json = await body.json();
        console.log(`json body is ${json}`);
        return json;
    }

    return (
        <>
            <PageTitle title={"My Moments"} />
            <div className="home">
                <Stack spacing={1}
                    sx={{ mb: 2 }}
                    alignItems="center"
                    justifyContent="center">

                    <Typography
                        variant="h4"
                        align="center">
                        My Moments
                    </Typography>
                    <Button
                        color="success"
                        variant="contained"
                        onClick={() => history.push('/moments/new')}
                        sx={{ mb: 2 }}>
                        Create a new moment!
                    </Button>
                </Stack>
                {isLoading ?
                    (
                        <Stack spacing={1}>
                            <Skeleton variant="rectangular" height={10} />
                            <Skeleton variant="rectangular" height={20} />
                            <Skeleton variant="rectangular" height={50} />
                        </Stack>
                    )
                    :
                    (
                        <MomentList
                            moments={moments}>
                        </MomentList>
                    )
                }

            </div>
        </>
    )
}