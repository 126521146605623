import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { useParams, useHistory } from "react-router-dom";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { del, put } from "aws-amplify/api";

// mui imports
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Grid from '@mui/material/Grid';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import TextField from '@mui/material/TextField';

export default function MomentSettings(props) {

    const history = useHistory();
    const [updating, setUpdating] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showMessage, setShowMessage] = useState(true);
    const [newName, setNewName] = useState("");
    const [isSecret, setIsSecret] = useState();
    const [isJoinable, setIsJoinable] = useState();
    const [momentDate, setMomentDate] = useState(new Date());

    // reload the newName, secret and date each time we receive an update from the Moment component
    useEffect(() => {
        setNewName(props.name);
        setIsSecret(props.secret);
        setIsJoinable(props.joinable);
        if (props.date != null) { // used to handle if a date does not exist for the Moment (TODO: should dates be mandatory?)
            setMomentDate(new Date(props.date));
        }
    }, [props.name, props.secret, props.date]);


    function handleClose() {
        setNewName(props.name); // reset the newName value to the original
        setMomentDate(new Date(props.date));
        setUpdating(false);
        setSuccess(false);
        props.setShowSettings(false);
    };

    async function updateMoment() {
        setShowMessage(true);
        setUpdating(true);

        const { username } = await getCurrentUser();
        const { tokens } = await fetchAuthSession();

        const input = {
            apiName: 'boards',
            path: `/board/${props.id}`,
            options: {
                headers: {
                    Authorization: tokens.idToken,
                    cognitoUsername: username
                },
                body: {
                    "name": newName,
                    "secret": isSecret,
                    "date": momentDate.toISOString().slice(0, 19).replace(/T/g, " "), // convert date to UTC and YYYY-MM-DD hh:mm:ss format for MySQL
                    "joinable": isJoinable
                },
            }
        }
        const { body } = await put(input).response;
        const retVal = await body.json();
        console.log(retVal);
        setUpdating(false);
        setSuccess(true);
        props.updateMomentCallback();
    }


    async function handleBoardDelete() {
        if (window.confirm('Are you sure you want to delete this Moment? This action cannot be undone.')) {
            console.log("Deleting moment now");
            await deleteBoard();
            history.push("/mymoments");
        } else {
            console.log("User didn't accept, moment was not deleted");
        }
    }

    async function deleteBoard() {
        const { username } = await getCurrentUser();
        const { tokens } = await fetchAuthSession();
        const input = {
            apiName: 'boards',
            path: `/board/${props.id}`,
            options: {
                headers: {
                    Authorization: tokens.idToken,
                    "cognitousername": username
                }
            }
        }
        const { body } = await del(input).response;
        console.log(body);
        return;
    }

    function renderUpdateAlerts() {
        if (showMessage) {
            if (updating) {
                return (
                    <Alert className="text-center" variant="warning" dismissible onClose={() => setShowMessage(false)}>
                        Updating...
                    </Alert>
                )
            } else if (success) {
                return (
                    <Alert className="text-center" variant="success" dismissible onClose={() => setShowMessage(false)}>
                        Update successful!
                    </Alert>
                )
            }
        }
    }

    return (
        <>
            <Modal show={props.showSettings} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Moment Settings
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                Name
                            </Form.Label>
                            <Form.Control
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                Visibility
                            </Form.Label>

                            <Grid alignItems='center' container sx={{ pt: 1, pb: 1 }}>
                                <Grid item sx={{ pr: 0.5 }}>
                                    <Form.Check
                                        type="radio"
                                        label="Public"
                                        checked={!isSecret}
                                        onChange={(e) => setIsSecret(0)}
                                    />
                                </Grid>
                                <Grid item sx={{ pr: 0.5 }}>
                                    <LockOpenOutlinedIcon color="success" />
                                </Grid>
                            </Grid>

                            <Grid alignItems='center' container sx={{ pt: 1, pb: 1 }}>
                                <Grid item sx={{ pr: 0.5 }}>
                                    <Form.Check
                                        type="radio"
                                        label="Secret"
                                        checked={isSecret}
                                        onChange={(e) => setIsSecret(1)}
                                    />
                                </Grid>
                                <Grid item sx={{ pr: 0.5 }}>
                                    <LockOutlinedIcon color="error" />
                                </Grid>
                            </Grid>

                        </Form.Group>
                        <Form.Group>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    inputFormat="dd/MM/yyyy hh:mm a"
                                    label="Select Date & Time"
                                    value={momentDate}
                                    onChange={(date) => {
                                        setMomentDate(date.toJSDate());
                                    }}
                                />
                            </LocalizationProvider>
                        </Form.Group>
                        <Form>
                            <Form.Check
                                type="checkbox"
                                id="checkbox"
                                label="Allow people to view and join via URL"
                                checked={isJoinable}
                                onChange={() => setIsJoinable(!isJoinable)}
                            />
                        </Form>
                    </Form>
                    {renderUpdateAlerts()}
                </Modal.Body>
                <Modal.Footer>
                    <button className="m-2 btn btn-danger" onClick={handleBoardDelete}>Delete this moment</button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={updateMoment}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
