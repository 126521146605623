import React, { useState, useEffect } from "react";
import { get, put } from "aws-amplify/api";

// MUI imports
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function MomentSettings(props) {


    const [updating, setUpdating] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showMessage, setShowMessage] = useState(true);

    const [contributors, setAllContributors] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    useEffect(async () => {
        const { tokens } = await fetchAuthSession();
        const input = {
            apiName: 'boards',
            path: '/users',
            options: {
                headers: {
                    "accesstoken": tokens.accessToken
                },
            }
        }
        const { body } = await get(input).response;
        const result = await body.json();

        console.log("Users are:");
        console.log(result);
        setAllUsers(result);
        setAllContributors(result.filter(x => props.contributors.includes(x.Username)));
    }, []);


    function handleClose() {
        setUpdating(false);
        setSuccess(false);
        props.setVisible(false);
        setAllContributors(allUsers.filter(x => props.contributors.includes(x.Username))); // reset the contributors
    };

    async function updateContributors() {
        setShowMessage(true);
        setUpdating(true);

        // TODO secure this API call
        const { username } = await getCurrentUser();
        const input = {
            apiName: 'boards',
            path: `/contributors/${props.id}`,
            options: {
                headers: {
                    "cognitousername": username
                },
                body: {
                    contributors: contributors.map(a => a.Username), // send only the Usernames
                    momentName: props.momentName,
                    momentOwnerGivenName: props.MomentOwnerGivenName,
                    momentOwnerFamilyName: props.MomentOwnerFamilyName,
                    momentURL: props.momentURL
                }
            }
        }
        const { body } = await put(input).response;
        const result = await body.json();

        console.log(result);
        setUpdating(false);
        setSuccess(true);
        props.updateMomentCallback(); // re-get the moment data so we get the update
    }


    function renderAlerts() {
        if (showMessage) {
            if (updating) {
                return (
                    <Alert
                        className="text-center"
                        severity="warning"
                        onClose={() => setShowMessage(false)}
                        sx={{ mt: 2 }}
                    >
                        Updating...
                    </Alert>
                )
            } else if (success) {
                return (
                    <Alert
                        className="text-center"
                        severity="success"
                        onClose={() => setShowMessage(false)}
                        sx={{ mt: 2 }}
                    >
                        Update successful!
                    </Alert>
                )
            }
        }
    }

    return (

        <Dialog open={props.visible}>
            <DialogTitle>Add People</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    These people will be able to upload pictures and videos to your Moment and also add or remove other people. They will also get an email notification telling them they've been added.
                </DialogContentText>
                <Autocomplete
                    multiple
                    id="fixed-tags-demo"
                    value={contributors}
                    onChange={(event, newValue) => { setAllContributors([...newValue,]); console.log(newValue); }}
                    options={allUsers}
                    getOptionLabel={(user) => user.Attributes.find(x => x.Name === "given_name").Value + " " + user.Attributes.find(x => x.Name === "family_name").Value}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((user, index) => (
                            <Chip
                                label={user.Attributes.find(x => x.Name === "given_name").Value + " " + user.Attributes.find(x => x.Name === "family_name").Value}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField {...params} label="Add people!" placeholder="Add someone...." />
                    )}
                    sx={{ mt: 2 }}
                />
                {renderAlerts()}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => { handleClose() }}>Cancel</Button>
                <Button variant="contained" onClick={updateContributors}>Update</Button>
            </DialogActions>
        </Dialog>
    );
}
