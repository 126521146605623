/*
    This component renders a number of <ContributorChip/> components
*/

// React imports
import React from "react";

// MUI imports
import Grid from '@mui/material/Grid';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Typography from '@mui/material/Typography';

// local imports
import { MomentData } from "../interfaces/interfaces";
import ContributorChip from "./ContributorChip";
import { acceptedPhotoFileTypes, acceptedVideoFileTypes } from "../libs/vars";


interface ContributorChipsProps {
    moment: MomentData
}


export default function ContributorChips(props: ContributorChipsProps) {

    if (props.moment.info.contributors.length === 0) {
        return (<></>);
    }
    else {
        return (
            <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{ mb: 2, pb: 2 }}>
                <Grid container item xs={12} textAlign="center" justifyContent="center">
                    <Grid
                        item sx={{ pr: 0.5 }}>
                        <PeopleOutlineIcon />
                    </Grid>
                    <Grid item sx={{ pl: 0.5 }}>
                        <Typography
                            variant="subtitle1">
                            Attendees
                        </Typography>
                    </Grid>
                </Grid>
                {
                    props.moment.info.contributors.map(({ username, givenName, familyName }) => (
                        <Grid
                            key={username}
                            item
                        >
                            <ContributorChip
                                name={`${givenName} ${familyName}`}
                                numPhotos={props.moment.files.filter(file => file.owner.username === username && acceptedPhotoFileTypes.includes(file.fileType)).length}
                                numVideos={props.moment.files.filter(file => file.owner.username === username && acceptedVideoFileTypes.includes(file.fileType)).length}
                            />
                        </Grid>
                    ))
                }
            </Grid >
        )
    }
}
