/*
    This renders the Join Dialog/Modal that is shown when a user
    clicks the JoinButton component. The reason that it is a separate component
    is because it is wrapped in the Amplify Authenticator component.
*/


// React imports
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// mui imports
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';

// amplify imports
import { getCurrentUser, fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';

// local imports
import { put } from 'aws-amplify/api';


interface JoinButtonProps {
    closeModal(): void;
    updateMomentCallback(): any,
}

export default function JoinButton({ closeModal, updateMomentCallback }: JoinButtonProps) {

    const [givenName, setGivenName] = useState<string | undefined>(undefined);
    const [username, setUsername] = useState<string>('');
    const [loading, setLoading] = useState(false);

    // @ts-ignore todo fix later
    const { id: momentID } = useParams();


    useEffect(() => {
        //setLoading(true);
        getDetails();
        loadName();
        //setLoading(false);

        async function getDetails() {
            const { username } = await getCurrentUser();
            console.log(`The username: ${username}`);
            setUsername(username);
        }

        async function loadName() {
            const attributes = await fetchUserAttributes();
            setGivenName(attributes.given_name ?? 'Unknown');
            //setFamilyName(attributes.family_name ?? 'Unknown');
        }
    }, [])

    async function joinClicked() {
        setLoading(true);
        // TODO confirm this API call is secure

        const { tokens } = await fetchAuthSession();
        const idToken = tokens?.idToken ?? {};

        const input = {
            apiName: 'boards',
            path: `/public/contributors/${momentID}`,
            options: {
                body: {
                    idToken: idToken,
                }
            }
        }

        // @ts-ignore todo fix later
        const { body } = await put(input).response;
        const result = await body.json();
        console.log(`Result is ${result}`);

        // reload the moment
        updateMomentCallback();

        // close dialog
        closeModal();
    }

    return (
        <>
            {givenName === undefined
                ?
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" height={10} />
                    <Skeleton variant="rectangular" height={20} />
                </Stack>
                :
                <>
                    <DialogTitle id="alert-dialog-title">
                        {`Add yourself to this moment, ${givenName}?`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`You'll be able to upload your own photos and videos to for all to see! You can leave at any time.`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={closeModal}
                            sx={{ margin: '10px' }}
                            variant="contained"
                            color="error">
                            Cancel
                        </Button >
                        <LoadingButton
                            sx={{ margin: '10px' }}
                            variant="contained"
                            color="success"
                            loading={loading}
                            startIcon={<AddIcon />}
                            loadingPosition="start"
                            onClick={() => joinClicked()}>
                            Join!
                        </LoadingButton >


                    </DialogActions>
                </>
            }
        </>
    )
}