import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

//local imports
import Routes from "./Routes";
import CustomNavbar from "./components/CustomNavbar.js";

const theme = createTheme({
    palette: {
        vestij: {
            // light: will be calculated from palette.primary.main,
            main: '#F666EF',
            dark: '#E115D8',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
            contrastText: '#fff',
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Authenticator.Provider>
                <div className="App container py-3">
                    <CustomNavbar />
                    <Routes />
                </div>
            </Authenticator.Provider>
        </ThemeProvider>
    );
}

export default App;
