/*
    This components renders the data inside contributor chips on the Moment
    page including the name and optionally the number of photos they uploaded
*/

// React imports
import React from "react";

// MUI imports
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import VideocamIcon from '@mui/icons-material/Videocam';

interface ContributorChipProps {
    name: string,
    numPhotos: number,
    numVideos: number
}

export default function ContributorChip(props: ContributorChipProps) {

    return (

        <Chip
            sx={{ fontSize: 14 }}
            variant="outlined"
            label={
                <Stack direction="row" spacing={0.6} alignItems="center">
                    <Typography>
                        {props.name}
                    </Typography>

                    {
                        props.numPhotos > 0
                        &&
                        <>
                            <PhotoCameraIcon color="info" fontSize="small" />
                            <Typography>
                                {props.numPhotos}
                            </Typography>
                        </>
                    }

                    {
                        props.numPhotos > 0 && props.numVideos > 0 &&
                        <Divider orientation="vertical" flexItem />
                    }

                    {props.numVideos > 0
                        &&
                        <>
                            <VideocamIcon color="info" fontSize="medium" />
                            <Typography>
                                {props.numVideos}
                            </Typography>
                        </>
                    }
                </Stack>
            }
        />



    );

}