// React imports
import React from 'react';

// Route imports
import { useHistory } from "react-router-dom";

// MUI imports
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

// local imports
import PageTitle from '../components/PageTitle';

export default function Home() {

    const history = useHistory();

    return (
        <>
            <PageTitle title={"Home"} />
            <Box
                justifyContent="center"
                alignItems="center"
                sx={{ display: 'flex' }}>
                <Box
                    sx={{ width: '100%', maxWidth: 500 }}>
                    <Box>
                        <Typography
                            sx={{ "& .pink-text": { color: '#F666EF' } }}
                            variant="h1"
                            gutterBottom>
                            Never miss a <span className="pink-text">moment</span>.
                        </Typography>
                    </Box>
                    <Box
                        sx={{ mt: 1 }}>
                        <Typography
                            sx={{ "& .pink-text": { color: '#F666EF' } }}
                            variant="h5"
                            gutterBottom>
                            Vestij brings people together with everything from international concerts to grand final matches to birthday parties.
                            It's the <span className="pink-text">new social network</span> that let's you re-live the real life
                            <span className="pink-text"> moments</span> that enrich our lives.
                        </Typography>
                    </Box>

                    <Box
                        sx={{ mt: 3 }}>
                        <Typography
                            sx={{ "& .pink-text": { color: '#F666EF' } }}
                            variant="subtitle1"
                            gutterBottom>
                            Vestij is made up of <span className="pink-text">Moments</span> that are
                            created by you.
                            <span className="pink-text"> Moments</span> can be public (that anyone
                            in the world can see or join) or secret (only people you invite can see them).
                        </Typography>
                    </Box>

                    <Box
                        sx={{ mt: 3 }}>
                        <Typography
                            variant="h6"
                            gutterBottom>
                            To get started on Vestij:
                        </Typography>
                    </Box>

                    <Box
                        sx={{ mt: 1, "& .pink-text": { color: '#F666EF' } }}>
                        <Typography component="ol">
                            <li>Create a <span className="pink-text">Moment</span> or join an existing one</li>
                            <li>Upload your photos and videos and invite others who were there to do the same</li>
                            <li>Now all your photos and videos will appear together in the <span className="pink-text">Moment</span></li>
                            <li>Your <span className="pink-text">Moment</span> will be easily accessible for yourself to go back and explore and enjoy at any time</li>
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography
                            variant="subtitle1"
                            gutterBottom>


                        </Typography>
                    </Box>

                    <Box sx={{ mt: 4 }}>

                        <Stack spacing={2} direction="column">
                            <Button
                                sx={{
                                    '&:hover': {
                                        color: 'white', // or any other initial color
                                    }
                                }}
                                onClick={() => { history.push("/public") }}
                                color="primary"
                                variant="contained">
                                View Public Moments</Button>
                            <Button
                                sx={{
                                    '&:hover': {
                                        color: 'white', // or any other initial color
                                    }
                                }}
                                onClick={() => { history.push("/moments/new") }}
                                color="primary"
                                variant="contained">Create your own Moment</Button>
                        </Stack>
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Typography>
                            This web page is a Progressive Web App (PWA), so you can download it
                            as if it was an app on your phone! To do so in your browser click 'Add to home screen'.
                        </Typography>
                    </Box>
                </Box>
            </Box >
        </>

    );
}